import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Catalogue from '~components/Catalogue'
import TextImages from '~components/TextImages'
import TextImage from '~components/TextImage'
import ReviewsCarousel from '~components/ReviewsCarousel'
import BlurIn from '~components/BlurIn'
import TextSlider from '~components/TextSlider'
import { mobile } from '../styles/global'
import styled from '@emotion/styled'
import SanityLink from '../components/SanityLink'
import Button from '../components/Button'
import Image from '../components/Image'
import Section from '../components/Section'
import RichText from '../components/RichText'
import ArticleRow from '../components/ArticleRow'
import ScrollJack from '../components/ScrollJack'

const ClientSideRippleImage = React.lazy(() =>
	import('~components/RippleImage')
)
const IndexPage = ({ data }) => {
	const { title, content } = data.page
	const isSSR = typeof window === 'undefined'
	const instaPosts = content?.instagramTiles
	return (
		<>
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<DataViewer data={data} name='data' />
			{!isSSR && (
				<React.Suspense fallback={<div />}>
					<ClientSideRippleImage
						isPreview={process.env.GATSBY_PREVIEW}
						asset={content?.headerImage?.asset} 
						mobileAsset={content?.headerImageMobile?.asset}
					/>
				</React.Suspense>
			)}
			<BlurIn>
				<TextSlider text={content?.introduction} />
			</BlurIn>
			<BlurIn>
				<Catalogue data={content.catalogue} />
			</BlurIn>
			<BlurIn>
				<TextImages 
					content={content.textImages}
					reverse={content.textImages?.reverse}
					imagePadding={content.textImages?.imagePadding}
				/>
			</BlurIn>
			<BlurIn>
				<TextImage
					content={content.textImage}
					reverse={content.textImage?.reverse}
					imagePadding={content.textImage?.imagePadding}
				/>
			</BlurIn>
			{ content.exploreScents.showSection &&
				<ScrollJack content={content.exploreScents}/>
			}
			<BlurIn>
				<ReviewsCarousel content={content.reviews}/>
			</BlurIn>
			<BlurIn>
				<AboutTiles>
					{data?.aboutPages?.aboutPages?.map(page => (
						<AboutTile key={page?.title}>
							<AboutTitle link={{ 								
								linkType: 'internal', 
								document: {
									slug: page?.slug,
								}
							}}>
								<h1>{page?.title}</h1>
							</AboutTitle>
							<AboutImage link={{ 								
								linkType: 'internal', 
								document: {
									slug: page?.slug,
								}
							}}>
								<Image 
									image={page?.content?.headerImage} 
									aspectRatio={1.48}

								/>
							</AboutImage>
							<AboutText>
								<RichText content={page?.content?.introduction}/>
							</AboutText>
							<Button 
								className='h3' 
								link={{ 								
									linkType: 'internal', 
									document: {
										slug: page?.slug,
									}
								}}>
									Discover
							</Button>
						</AboutTile>
					))}
				</AboutTiles>
			</BlurIn>
			<BlurIn>
				<ArticleRow 
					content={content?.featuredArticles} 
					featureHeading={'Journal'} 
					sliderMobile={true}
					allLink={true}
				/>
			</BlurIn>
			<BlurIn>
				<Instagram>
					<InstHeading>
						<Handle><a href={`https://instagram.com/${content.instagramHandle}`} target="_blank" rel="noreferrer">@{content.instagramHandle}</a></Handle>
						<TagLine>{content.instagramHeading}</TagLine>
					</InstHeading>
					{instaPosts && instaPosts[0] &&
						<Post href={instaPosts[0]?.url} target="_blank" css={css`
							margin-top: 280px;
							grid-column: 3/6;
							${mobile}{
								margin-top: 0px;
								grid-row: 3;
								grid-column: 1/4;
								margin-bottom: 50px;
							}
						`}>
							<Image css={css`width: 100%;margin-bottom: var(--s);`} image={instaPosts[0]?.image}/>
							<h4 css={css`text-align: center;`}>@{content.instagramHandle}</h4>
						</Post>
					}
					{instaPosts && instaPosts[1] &&
						<Post href={instaPosts[1]?.url} target="_blank" css={css`
							grid-column: 7/11;
							${mobile}{
								grid-row: 2;
								grid-column: 3/7;
								margin-bottom: 50px;
							}
						`}>
							<Image css={css`width: 100%;margin-bottom: var(--s);`} image={instaPosts[1].image}/>
							<h4 css={css`text-align: center;`}>@{content.instagramHandle}</h4>
						</Post>
					}
					{instaPosts && instaPosts[2] &&
						<Post href={instaPosts[2]?.url} target="_blank" css={css`
							margin-top: 60px;
							grid-column: 8/10;
							${mobile}{
								grid-row: 4;
								grid-column: 4/7;
								margin-top: 0px;
							}
						`}>
							<Image image={instaPosts[2]?.image} css={css`width: 100%;margin-bottom: var(--s);`} />
							<h4 css={css`text-align: center;`}>@{content.instagramHandle}</h4>
						</Post>
					}

				</Instagram>
			</BlurIn>
		</>
	)
}

const AboutTiles = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border-bottom: 1px solid var(--black);
`
const AboutTile = styled.div`
	padding: var(--xxxl) var(--m);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	&:first-of-type{
		border-right: 1px solid var(--black);
	}
	${mobile}{
		padding: var(--l) var(--m);
	}
`
const AboutTitle = styled(SanityLink)`
	margin-bottom: var(--xl);
	display: block;
	${mobile}{
		margin-bottom: var(--l);
	}
`
const AboutImage = styled(SanityLink)`
	margin-bottom: var(--xl);
	overflow: hidden;
	display: block;
	max-width: 420px;
	width: 100%;
	img{
		transform: scale3d(1, 1, 1);
		transition: transform 0.3s;
	}
	&:hover{
		img{
			transform: scale3d(1.02, 1.02, 1);
		}
	}
	${mobile}{
		margin-bottom: var(--l);
	}
`
const AboutText = styled.h3`
	max-width: 420px;
	margin-bottom: var(--m);
	${mobile}{
		display: none;
	}
`
const Instagram = styled(Section)`
	padding-top: var(--xxxl);
	margin-bottom: var(--xxxl);
`
const InstHeading = styled.div`
	grid-column: span 12;
	text-align: center;
	margin-bottom: var(--xxxl);
	${mobile}{
		margin-bottom: var(--xxl);
		grid-column: span 6;
	}
`
const Handle = styled.h3`
	margin-bottom: var(--m);
`
const TagLine = styled.h1`
	${mobile}{
		padding: 0 15%;
	}
`
const Post = styled.a`
	display: block;
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query IndexPageQuery {
		page: sanityHomePage {
			title
			content {
				seo {
					...seo
				}
				headerImage {
					asset {
						url
							metadata {
							dimensions {
								height
								width
							}
						}
					}
				}
				headerImageMobile {
					asset {
						url
							metadata {
							dimensions {
								height
								width
							}
						}
					}
				}
				introduction
				catalogue {
					...productCatalogue
				}
				textImages {
					...textImages
				}
				textImage {
					...textImage
				}
				exploreScents {
					showSection
					...exploreScents
				}
				reviews {
					...reviews
				}
				featuredArticles{
					...articleRow
				}
				instagramHeading
				instagramHandle
				instagramTiles {
					image{
						...imageWithAlt
					}
					url
				}
			}
		}
		aboutPages: allSanityAboutPage(sort: {fields: _createdAt, order: DESC}) {
			aboutPages: nodes {
				title
				slug {
					current
				}
				content {
					headerImage {
						...imageWithAlt
					}
					introduction: _rawIntroduction
				}
			}
		}
	}
`

export default IndexPage
